import React from 'react';
import { Link } from 'react-router-dom';
import SliderRecentlyProject from './components/SliderRecentlyProject';


const RecentlyProject = () => {
  return (
    <>

      <div className="case-area py-120">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Projects</span>
                <h2 className="site-title">Featured Projects</h2>
                <div className="heading-divider" />
              </div>
            </div>
          </div>
          <div className="row popup-gallery">
            <div className="case-slider owl-carousel owl-theme">
              
            </div>
            <SliderRecentlyProject />
          </div>
        </div>
      </div>



    </>
  )
}

export default RecentlyProject