import React from 'react'
import { Link } from 'react-router-dom';


const HomeAbout = () => {
  return (
    <>

      <div className="about-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-left">
                <div className="about-img">
                  <img className="about-img-1" src="assets/img/about/01.jpg" alt />
                  <img className="about-img-2" src="assets/img/about/02.jpg" alt /> 
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline">About Us</span>
                  <h2 className="site-title">Welcome to our Facade and Landscape Designing and Construction Services!</h2>
                </div>
                <p className="about-text">
                Facade & Landscape we are passionate about creating beautiful and functional outdoor spaces that enhance the aesthetic appeal and value of your property. Our team of skilled designers and construction experts work together to provide top-notch services that exceed your expectations.
                </p>
                <p className="about-text mt-3">
                  With our expertise in both facade and landscape designing, we can help you transform your property into a welcoming and visually stunning space. We offer a wide range of services, including design consultation, project management, and construction, to ensure that your project is completed on time and to your satisfaction.
                </p>


                <div class="about-list-wrapper">
                  <ul class="about-list list-unstyled">
                    <li>
                      <div class="icon"><span class="far fa-check-circle"></span></div>
                      <div class="text">
                        <p>We Have Highly Professional Team.</p>
                      </div>
                    </li>
                    <li>
                      <div class="icon"><span class="far fa-check-circle"></span></div>
                      <div class="text">
                        <p>We Are Commited.</p>
                      </div>
                    </li>
                    <li>
                      <div class="icon"><span class="far fa-check-circle"></span></div>
                      <div class="text">
                        <p>We Deliver Best Results.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-lg-5'> </div>



    </>
  )
}

export default HomeAbout