import React from 'react'
import { Link } from 'react-router-dom';


const WhyUs = () => {
  return (
    <>




      <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/breadcrumb.jpg)' }}>
        <div className="container">
          <h2 className="breadcrumb-title">Why Us</h2>
          <ul className="breadcrumb-menu">
            <li><Link to="/">Home</Link></li>
            <li className="active">Why Us</li>
          </ul>
        </div>
      </div>



      <div className="service-area bg py-120">
        <div className="container">
          <h3 className=' text-center'>Why Us</h3>
          <p className=' mt-5 '>
            When it comes to facade and landscape designing and construction, you have many options to choose from. However, not all companies are created equal. Here are a few reasons why you should choose [Type Here Your Business] for your next project:
          </p>
          <div className="row g-1 mt-5">
            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/01.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-staircase" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Experience</a>
                  </h3>
                  <p className="service-text">
                     Our team of designers, architects, and construction experts has years of experience in the industry, and we have completed countless successful projects for clients across India
                  </p>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/02.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-workspace" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Commitment to Quality</a>
                  </h3>
                  <p className="service-text">
                    We take pride in our work and are committed to delivering exceptional quality in every project we undertake. We use only the best materials and advanced techniques to ensure that your project is built to last and meets your specific needs and preferences.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/04.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-interior-design" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Sustainability</a>
                  </h3>
                  <p className="service-text">
                    We are committed to sustainability and eco-friendliness in all our projects. We use environmentally friendly materials and techniques whenever possible, to minimize our impact on the environment and create beautiful, sustainable outdoor spaces.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/06.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-interior-design-2" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Attention to Detail</a>
                  </h3>
                  <p className="service-text">
                    We believe that the little things matter, and we pay attention to every detail in every project we undertake. From the initial design phase to the final construction, we ensure that every aspect of your project is thoughtfully and carefully considered.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/07.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-decorating" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Customer Service</a>
                  </h3>
                  <p className="service-text">
                    We are dedicated to providing excellent customer service to our clients. We understand that communication is key to a successful project, and we work closely with our clients to ensure that their vision and goals are met throughout every step of the process.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/08.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-blueprint" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Competitive Pricing</a>
                  </h3>
                  <p className="service-text">
                    We offer competitive pricing for our services, without compromising on quality or professionalism. We understand that every project is unique, and we work with our clients to find solutions that fit their budget.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





    </>
  )
}

export default WhyUs