import React from 'react'
import { Link } from 'react-router-dom';


const OurApproach = () => {
  return (
    <>
    
    <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/breadcrumb.jpg)' }}>
        <div className="container">
          <h2 className="breadcrumb-title">Approach</h2>
          <ul className="breadcrumb-menu">
            <li><Link to="/">Home</Link></li>
            <li className="active">Approach</li>
          </ul>
        </div>
      </div>
      <div className="blog-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Approach</span>
                <div className="heading-divider" />
                <p>
                Facade & Landscape we believe that successful facade and landscape designing and construction requires a thoughtful and collaborative approach. Here's what you can expect when you work with us.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/01.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                <h4 class="blog-title text-center">
                    <p>Discovery</p>
                  </h4>
                  <p>We start by getting to know you, your property, and your vision for your outdoor space. We listen carefully to your ideas, goals, and preferences, and work with you to create a customized plan that meets your specific needs and budget.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/02.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Design</p>
                  </h4>
                  <p>Our team of expert designers will work with you to create a detailed design plan that incorporates all of your ideas and preferences. We use advanced software and visualization tools to help you visualize your outdoor space and make informed decisions about the design and materials.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/03.png" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                <h4 class="blog-title text-center">
                    <p>Construction</p>
                  </h4>
                  <p>Once the design plan is finalized, our skilled contractors will bring your vision to life, using high-quality materials and advanced techniques to ensure that your project is built to last. We take pride in our attention to detail and our commitment to quality, and we work closely with you throughout the construction process to ensure that your project meets your expectations.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/01.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                <h4 class="blog-title text-center">
                    <p>Maintenance</p>
                  </h4>
                  <p>We offer comprehensive maintenance services to ensure that your outdoor space remains beautiful and functional for years to come. We believe that regular maintenance is essential to preserving the beauty and longevity of your project, and we work with you to develop a customized maintenance plan that meets your specific needs and budget.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/02.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Sustainability</p>
                  </h4>
                  <p>We are committed to sustainability and eco-friendliness in all our projects. We use environmentally friendly materials and techniques whenever possible, to minimize our impact on the environment and create beautiful, sustainable outdoor spaces.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details container">
                <blockquote className="blockqoute">
                Facade & Landscape we are committed to delivering exceptional quality and service to our clients, and to ensuring that every project is completed on time, on budget, and to your exact specifications. Contact us today to learn more about our project execution process and how we can help bring your outdoor space to life.
                </blockquote>
            </div>

    </>
  )
}

export default OurApproach