import React from 'react'
import { Link } from 'react-router-dom';


const Services = () => {
  return (
    <>



      <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/breadcrumb.jpg)' }}>
        <div className="container">
          <h2 className="breadcrumb-title">Services</h2>
          <ul className="breadcrumb-menu">
            <li><Link to="/">Home</Link></li>
            <li className="active">Services</li>
          </ul>
        </div>
      </div>



      <div className="service-area bg py-120">
        <div className="container">
          <h3 className=' text-center'>Services</h3>
          <p className=' mt-5 '>
            At [Type Here Your Business], we offer a wide range of services to meet your facade and landscape designing and construction needs. Whether you are looking to create a beautiful outdoor space for your home or business, we have the expertise and experience to help bring your vision to life. Here are some of the services we offer.
          </p>
          <div className="row g-1 mt-5">
            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/01.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-staircase" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Facade Designing</a>
                  </h3>
                  <p className="service-text">
                    Our team of expert designers can help you create a unique and stunning facade for your home or business, using high-quality materials and advanced techniques.
                  </p>

                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/04.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-interior-design" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Construction</a>
                  </h3>
                  <p className="service-text">
                    Our team of skilled contractors can bring your facade and landscape designs to life, using advanced techniques and high-quality materials to ensure that your project is built to last.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/02.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-workspace" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Landscape Designing</a>
                  </h3>
                  <p className="service-text">
                    We specialize in creating beautiful and functional outdoor spaces that enhance the beauty and functionality of your property. From gardens and green spaces to water features and hardscapes, we can help you create the outdoor oasis of your dreams.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/06.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-interior-design-2" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Maintenance</a>
                  </h3>
                  <p className="service-text">
                    We offer comprehensive maintenance services to ensure that your outdoor space remains beautiful and functional for years to come. From regular cleaning and upkeep to seasonal maintenance and upgrades, we can help you keep your outdoor space looking its best.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/07.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-decorating" />
                  </div>
                  <h3 class="service-title">
                    <a to="/">Consultation</a>
                  </h3>
                  <p className="service-text">
                    We offer consultation services to help you plan and design your outdoor space, providing expert advice and guidance to ensure that your project meets your specific needs and preferences.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="blog-details container">
        <blockquote className="blockqoute">
          At [Type Here Your Business], we are committed to delivering exceptional quality and service to our clients, using sustainable and environmentally friendly materials and techniques whenever possible. Contact us today to learn more about our services and how we can help bring your outdoor space to life.
        </blockquote>
      </div>


      <div className="service-single-area py-120">
        <div className="container">
          <div className="service-single-wrapper">
            <div className="row">

              <div className="col-xl-6 col-lg-8">
                <div className="service-details">
                  <div className="service-details-img mb-30">
                    <img src="assets/img/service/single.jpg" alt="thumb" />
                  </div>

                </div>
              </div>

              <div className="col-xl-6 col-lg-4">
                <div className="service-sidebar">
                  <div className="widget category">
                    <div className="site-heading mb-3">
                      <span className="site-title-tagline">Exterior Description</span>
                      <h2 className="site-title">Enhance Your Home's Curb Appeal with Stunning Exterior Designs!</h2>
                    </div><br></br>

                    <div className="category-list">
                      <p className="about-text">
                        Your home's exterior is the first impression visitors and passersby have of your residence. Make it a statement with a well-designed exterior that reflects your style and adds curb appeal. Whether you prefer modern, traditional, or transitional aesthetics, the exterior design of your house or apartment can elevate its overall look and feel.
                      </p>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="case-area py-120">
        <div className="container">
          <div className="row popup-gallery">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/case/01.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/case/01.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Contemporary Charm</a></h4>
                      <small>Modern Exterior Designs</small>
                    </div>
                  </div>
                </div>
                <p>Sleek lines, minimalist finishes, and ample use of glass define modern exterior designs. Embrace the beauty of simplicity with a modern home that features large windows, clean forms, and a seamless connection between indoor and outdoor spaces. Choose materials like glass, steel, concrete, and wood in a neutral color palette with pops of accent colors for a chic and sophisticated look that's sure to turn heads.</p>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/case/02.png" alt />
                    <a className="popup-img case-link" to="assets/img/case/02.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Timeless Elegance</a></h4>
                      <small>Traditional Exterior Designs</small>
                    </div>
                  </div>
                </div>
                <p>If you appreciate classic aesthetics, traditional exterior designs may be your perfect choice. With pitched roofs, dormer windows, and ornate trims, traditional homes exude timeless elegance. Choose materials such as brick, stone, or wood, and warm color palettes for a welcoming and sophisticated exterior. Classic architectural details add character and charm, making your home a true standout in any neighborhood</p>

              </div>
              <div className="col-md-6 col-lg-6 mt-5">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/case/03.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/case/03.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">The Best of Both Worlds</a></h4>
                      <small>Transitional Exterior Designs</small>
                    </div>
                  </div>
                </div>
                <p>For those who love the balance between modern and traditional styles, transitional exterior designs offer the best of both worlds. Blending contemporary and classic elements, transitional homes feature a mix of materials, textures, and colors. Achieve a harmonious look with symmetrical designs, balanced proportions, and a sense of understated sophistication that's both current and timeless.</p>
              </div>
              <div className="col-md-6 col-lg-6 mt-5">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/case/04.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/case/04.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Nature's Beauty</a></h4>
                      <small>Landscaping for Stunning Exteriors</small>
                    </div>
                  </div>
                </div>
                <p>Landscaping is a crucial part of any exterior design. Well-placed trees, plants, and outdoor amenities like patios and walkways can transform your home's exterior into a natural oasis. Thoughtfully designed landscaping enhances curb appeal, adds beauty and character, and creates a seamless connection between your home and its surrounding environment.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details container">
        <blockquote className="blockqoute">
          <h6 className="blockqoute-author">Elevate Your Home's Exterior Design</h6>
          In conclusion, the exterior design of your house or apartment plays a significant role in creating a striking first impression and adding curb appeal. Whether you prefer modern, traditional, or transitional styles, the right choice of materials, colors, and landscaping can elevate your home's exterior to new heights of beauty and charm. Make a statement with a well-designed exterior that reflects your personal style and enhances the overall aesthetic appeal of your home
        </blockquote>
      </div>




      <div className="about-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-left">
                <div className="about-img">
                  <img className="about-img-1" src="assets/img/about/01.jpg" alt />
                  <img className="about-img-2" src="assets/img/about/02.jpg" alt />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right">
                <div className="site-heading mt-5 ">
                  <span className="site-title-tagline mt-5">Services for landscape description</span>
                  <h2 className="site-title">Transform Your Outdoor Space into a Beautiful Landscape</h2>
                </div>
                <p className="about-text mb-5">
                  Landscape designing is the art of creating beautiful and functional outdoor spaces that enhance the overall aesthetics of your property. With a variety of landscape design types to choose from, you can transform your outdoor space into a natural oasis that reflects your personal style and meets your unique needs.
                </p>
                <p className="about-text mt-3">
                  A well-designed landscape can transform your outdoor space into a stunning and functional extension of your home. Whether you have a small backyard or a vast lawn, the right landscaping can add beauty, character, and value to your property.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-single-area py-120 bg">
        <div className="container">
          <div className="service-single-wrapper">
            <div className="row">

              <div className="col-xl-6 col-lg-4">
                <div className="service-sidebar">
                  <div className="widget category">
                    <div className="category-list">
                      <p className="about-text"><b>Create a Natural Oasis with Thoughtful Landscaping : </b>
                        With the right mix of plants, trees, and hardscape elements, you can create a natural oasis that enhances your outdoor living experience. Choose from a variety of landscape design styles, from modern and minimalist to traditional and ornate, to find a look that suits your taste and complements your home's architecture.
                      </p><br></br>
                      <p className="about-text"><b>Maximize Your Outdoor Living Space with Smart Design : </b>
                        With thoughtful landscape design, you can maximize your outdoor living space and create a functional area for relaxation, entertainment, and play. Incorporate elements like patios, decks, outdoor kitchens, and fire pits to create a seamless connection between your indoor and outdoor living spaces.
                      </p>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-xl-6 col-lg-8">
                <div className="service-details">
                  <div className="service-details-img mb-30">
                    <img src="assets/img/service/single.jpg" alt="thumb" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-single-area py-120">
        <div className="container">
          <div className="service-single-wrapper">
            <div className="row">

              <div className="col-xl-6 col-lg-8">
                <div className="service-details">
                  <div className="service-details-img mb-30">
                    <img src="assets/img/service/single2.jpg" alt="thumb" />
                  </div>

                </div>
              </div>


              <div className="col-xl-6 col-lg-4">
                <div className="service-sidebar">
                  <div className="widget category">
                    <div className="category-list">
                      <p className="about-text"><br></br><br></br>
                        <b>Add Value and Curb Appeal to Your Property : </b>
                        A well-designed landscape can significantly increase your property's value and curb appeal. Beautifully landscaped homes are more attractive to potential buyers and can sell for a higher price. In addition, a well-maintained landscape can improve your home's energy efficiency, reduce noise pollution, and provide a peaceful retreat from the hustle and bustle of daily life.
                      </p><br></br>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="blog-area py-120 bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <p>
                  Here are some of the most popular landscape design types to consider:
                </p>
                <div className="heading-divider" />

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/01.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Japanese Garden Design</p>
                  </h4>
                  <p>Japanese garden design is a traditional landscape design style that emphasizes harmony, balance, and tranquillity. This type of design often features water features, such as ponds and waterfalls, as well as rocks, stones, and carefully chosen plants. Japanese gardens are perfect for those who seek a peaceful retreat from the hustle and bustle of daily life.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/02.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Cottage Garden Design</p>
                  </h4>
                  <p>Cottage garden design is a charming and romantic landscape design style that often features a mix of plants, flowers, and herbs. This type of design is ideal for those who love a colourful and naturalistic look and appreciate the beauty of organic materials. Cottage gardens can be designed to look rustic, wild, or formal, depending on your personal style.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/03.png" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Modern Garden Design</p>
                  </h4>
                  <p>Modern garden design is a sleek and minimalist landscape design style that emphasizes clean lines, geometric shapes, and minimalistic finishes. This type of design is ideal for those who appreciate the beauty of simplicity and want to create a sophisticated outdoor space. Modern gardens often feature stone or concrete elements, such as patios and walkways, and a limited colour palette.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/01.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Mediterranean Garden Design</p>
                  </h4>
                  <p>Mediterranean garden design is a warm and inviting landscape design style that takes inspiration from the Mediterranean region. This type of design often features bright and bold colours, such as blue and yellow, and incorporates elements such as terracotta pots, fountains, and wrought iron accents. Mediterranean gardens are perfect for those who want to create a vibrant and welcoming outdoor space.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/02.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Tropical Garden Design</p>
                  </h4>
                  <p>Tropical garden design is a lush and exotic landscape design style that takes inspiration from tropical regions such as Southeast Asia and South America. This type of design often features a mix of bold and colourful plants, such as palms, ferns, and orchids, as well as water features and sculptures. Tropical gardens are perfect for those who want to create a unique and exotic outdoor space.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details container">
        <blockquote className="blockqoute">
          In conclusion, landscape designing is a versatile and creative art that allows you to transform your outdoor space into a beautiful and functional oasis. By exploring different landscape design types, you can find a style that suits your personal taste and meets your unique needs. Consider partnering with professional landscape designers to help you achieve stunning results and create a beautiful and functional outdoor space that you'll love for years to come.
        </blockquote>
      </div>
    </>
  )
}

export default Services