// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import { Autoplay, Pagination } from "swiper";

export default function HomeSlider() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        
        modules={[Autoplay,Pagination]}
        className="mainSlider"
      >
     
        <SwiperSlide>
               <div className="swiperContainer2">
              <div className="swipermain2">
                <div className="textDesign2">
                  <h2 className="text-white">Facade & Landscape Designer </h2>
                  <p className="text-white">  Best Exterior Designer In Hyderabad</p>
                </div>
              </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
             <div className="swiperContainer">
              <div className="swipermain">
                <div className="textDesign">
                  <h3 className="text-white">Best Exterior Designer In Hyderabad</h3>
                  <p className="text-white"> With Innovative Ideas</p>
                </div>
              </div>
             </div>
        </SwiperSlide>
      </Swiper>


    </>
  );
}

