import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';

import { Link } from 'react-router-dom';

const Hadear = () => {
  const [showAbout, setShowAbout] = useState(true);
  const [ismobile, setIsmobile] = useState(0);
  const closemenu = () => {
    document.getElementById("mytogglebtn").classList.add("collapsed");
    document.getElementById("menu").classList.remove("in");
  };

  return (
    <>


      <div className="header-top ">
        <div className="container-fluid px-lg-5">
          <div className="header-top-wrapper">
            <div className="header-top-right">
              <div className="header-top-contact">
                <ul>
                  <li><Link to="#"><span>Address:</span> Hyderabad, Telangana-500048</Link></li>                     <li>

                    <span>Email Us:</span>
                    <a
                      href="mailto: javidarchitects@gmail.com"
                      class="mail"
                    >
                      javidarchitects@gmail.com
                    </a>

                  </li>
                  <li><Link to="tel:javidarchitects@gmail.com"><span>Call Us:</span> (+91) 800 800 9832</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg" style={{position:"fixed"}}>
        <div className="container">
          <Link className="navbar-brand">
            <div className="logo-wrapper">
              <Link className='d-flex justify-content-center align-items-center' to="/">
                <img src="assets/img/logo/newlogo.jpg" alt="logo" />
              </Link>
            </div>
          </Link>
          <div className={ismobile ? "moblink" : "desklink"} onClick={() => setIsmobile(false)}>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <Link className="nav-link" to="/" data-bs-toggle="dropdown">Home</Link>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="/about" data-bs-toggle="dropdown">About us</Link>
                <ul className="dropdown-menu fade-up"

                  style={
                    showAbout
                      ? { display: "block" }
                      : { display: "none" }
                  }

                >
                  {/* <li><Link className="dropdown-item" to="/about" onClick={closemenu} >About </Link></li> */}
                  <li><Link className="dropdown-item" to="/missionvision"  >Mission & Vision</Link></li>
                  <li><Link className="dropdown-item" to="whyus" >Why Us</Link></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="/services" data-bs-toggle="dropdown">Service</Link>
                <ul className="dropdown-menu fade-up"

                  style={
                    showAbout
                      ? { display: "block" }
                      : { display: "none" }
                  }

                >
                  {/* <li><Link className="dropdown-item" to="/services">Service</Link></li> */}
                  <li><Link className="dropdown-item" to="/ourapproach">Our Approach</Link></li>
                </ul>
              </li>
              <li className="nav-item"><Link className="nav-link" to="/project ">Project</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/gallery">Gallery</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/contact">Contact</Link></li>
            </ul>
          </div>

          <button className='vavbutn' onClick={() => setIsmobile(!ismobile)}>
            {ismobile ? < IoMdClose /> : < GiHamburgerMenu />}
          </button>
        </div>
      </nav>
    </>
  )
}

export default Hadear