import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from 'react-icons/bs';

function Footer() {
  return (
    <>
      <footer className="footer-area">
        <div className="footer-widget">
          <div className="container">
            <div className="row footer-widget-wrapper pt-100 pb-70">
              <div className="col-md-6 col-lg-4">
                <div className="footer-widget-box about-us">
                  {/* <Link to="/" className="footer-logo">
                    <img src="assets/img/logo/newlogo.png" alt />
                  </Link> */}
                  <h4 className="footer-widget-title">About</h4>

                  <p className="mb-20">
                  Facade & Landscape is a one stop destination in this field by catering to all the requirements & necessities of design of your Dream Home and Commercial Space.
                  </p>
                  <ul className="footer-social">
                    <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="#"><i className="fab fa-instagram" /></a></li>
                    <li><a href="#"><i className="fab fa-twitter" /></a></li>
                    <li><a href="#"><i className="fab fa-youtube" /></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="footer-widget-box list">
                  <h4 className="footer-widget-title">Quick Links</h4>
                  <ul className="footer-list">
                    <li><Link to="/">- Home</Link></li>
                    <li><Link to="/about">- About Us</Link></li>
                    <li><Link to="/services">- Services</Link></li>
                    <li><Link to="/project">- Project</Link></li>
                    <li><Link to="/gallery">- Gallery</Link></li>
                    <li><Link to="/contact">- Contact Us</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="footer-widget-box list">
                  <h4 className="footer-widget-title">Explore</h4>
                  <ul className="footer-list">
                    <li><Link to="/missionvision">- Mission Vision</Link></li>
                    <li><Link to="/whyus">- Why Us</Link></li>
                    <li><Link to="/ourapproach">- Our Approach</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
              <div className="mapouter">
                <div className="gmap_canvas">
                <iframe className="gmap_iframe" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.com/maps?width=300&height=300&hl=en&q=Space Design Studio Hyderabad, Telangana-500048&t=&z=14&ie=UTF8&iwloc=B&output=embed" /><a href="https://pdflist.com/" alt="pdf download">Pdf download</a></div><style dangerouslySetInnerHTML={{__html: ".mapouter{position:relative;text-align:right;width:300px;height:300px;}.gmap_canvas {overflow:hidden;background:none!important;width:300px;height:300px;}.gmap_iframe {width:300px!important;height:300px!important;}" }} />
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <p className="copyright-text">
                  © Copyright <span id="date" /> <a href="https://dexterous.in/" target="_blank"> Dexterous Technology </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      
    </>
  );
}
export default Footer;
