import React from 'react'
import { Link } from 'react-router-dom';


const Gallery = () => {
    return (
        <>

            <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/breadcrumb.jpg)' }}>
                <div className="container">
                    <h2 className="breadcrumb-title">Gallery</h2>
                    <ul className="breadcrumb-menu">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Gallery</li>
                    </ul>
                </div>
            </div>


            <div className="service-single-area py-120">
                <div className="container">
                    <div className="service-single-wrapper">
                        <div className="row">

                            <div className="col-xl-6 col-lg-8">
                                <div className="service-details">
                                    <div className="service-details-img mb-30">
                                        <img src="assets/img/service/single.jpg" alt="thumb" />
                                    </div>

                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-4">
                                <div className="service-sidebar">
                                    <div className="widget category">
                                        <div className="category-list">
                                            <p className="about-text">
                                                Welcome to our gallery of facade and landscape designing and construction projects at Facade & Landscape. Here, you can see examples of our work and the high-quality craftsmanship that we bring to every project. From stunning outdoor spaces to elegant building facades, our gallery showcases the range of our expertise and the beauty of our designs.
                                            </p><br></br>
                                            <p className="about-text">
                                                We take pride in using the highest-quality materials and construction techniques, and our attention to detail is evident in every project we undertake. Whether you are looking for a simple outdoor patio or an elaborate building facade, we can help bring your vision to life.
                                            </p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <blockquote className="blockqoute">
                                Take a look at our gallery below to see some examples of our work. If you have any questions or would like to discuss your own project, please don't hesitate to contact us. We would be happy to answer any questions you have and schedule a free consultation to discuss your needs.
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>


            <div className="case-area py-120">
        <div className="container">
          <div className="row popup-gallery">
            <div className="row">

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/7.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/7.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/3.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/3.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#"> Convention Banquet & Function Hall</a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/4.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/4.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>






              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/8.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/8.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/9.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/9.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/10.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/10.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/11.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/11.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#"> Convention Banquet & Function Hall</a></h4>

                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/5.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/5.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>





              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/12.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/12.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/13.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/13.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/14.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/14.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/15.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/15.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#"> Convention Banquet & Function Hall</a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/17.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/17.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/16.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/16.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/6.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/6.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>



              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/18.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/18.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/19.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/19.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/20.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/20.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#"> Convention Banquet & Function Hall</a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/21.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/21.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/22.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/22.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/23.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/23.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>



              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/24.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/24.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/25.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/25.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/26.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/26.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#"> Farm House</a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/27.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/27.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/29.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/29.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/31.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/31.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/30.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/30.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/28.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/28.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>



            {/* <div className="case-area py-120">
                <div className="container">
                    <div className="row popup-gallery">
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="case-item">
                                    <div className="case-img">
                                        <img className="img-fluid" src="assets/img/case/01.jpg" alt />
                                        <a className="popup-img case-link" to="assets/img/case/01.jpg"> <i className="far fa-plus" /></a>
                                    </div>
                                    <div className="case-content">
                                        <div className="case-content-info">
                                            <h4><a className='text-white' to="#"> Room</a></h4>
                                            <small>Exterior</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="case-item">
                                    <div className="case-img">
                                        <img className="img-fluid" src="assets/img/case/02.png" alt />
                                        <a className="popup-img case-link" to="assets/img/case/02.png"> <i className="far fa-plus" /></a>
                                    </div>
                                    <div className="case-content">
                                        <div className="case-content-info">
                                            <h4><a className='text-white' to="#"> Modern Work</a></h4>
                                            <small>Exterior</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="case-item">
                                    <div className="case-img">
                                        <img className="img-fluid" src="assets/img/case/03.jpg" alt />
                                        <a className="popup-img case-link" to="assets/img/case/03.jpg"> <i className="far fa-plus" /></a>
                                    </div>
                                    <div className="case-content">
                                        <div className="case-content-info">
                                            <h4><a  className='text-white' to="#">Modern </a></h4>
                                            <small>Exterior</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="case-item">
                                    <div className="case-img">
                                        <img className="img-fluid" src="assets/img/case/04.jpg" alt />
                                        <a className="popup-img case-link" to="assets/img/case/04.jpg"> <i className="far fa-plus" /></a>
                                    </div>
                                    <div className="case-content">
                                        <div className="case-content-info">
                                            <h4><a className='text-white' to="#">Modern </a></h4>
                                            <small>Exterior</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="case-item">
                                    <div className="case-img">
                                        <img className="img-fluid" src="assets/img/case/05.jpg" alt />
                                        <a className="popup-img case-link" to="assets/img/case/05.jpg"> <i className="far fa-plus" /></a>
                                    </div>
                                    <div className="case-content">
                                        <div className="case-content-info">
                                            <h4><a className='text-white' to="#">Modern </a></h4>
                                            <small>Exterior</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="case-item">
                                    <div className="case-img">
                                        <img className="img-fluid" src="assets/img/case/06.jpg" alt />
                                        <a className="popup-img case-link" to="assets/img/case/06.jpg"> <i className="far fa-plus" /></a>
                                    </div>
                                    <div className="case-content">
                                        <div className="case-content-info">
                                            <h4><a className='text-white' to="#">Modern </a></h4>
                                            <small>Exterior</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="blog-details container">
                <blockquote className="blockqoute">
                    Thank you for considering Facade & Landscape for your facade and landscape designing and construction needs. We look forward to working with you to bring your outdoor space to life.
                    {/* <h6 className="blockqoute-author">XYZ</h6> */}
                </blockquote>
            </div>


        </>
    )
}

export default Gallery