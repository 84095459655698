import { useEffect } from 'react';
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";

import './App.css';
import Mainlayout from './components/Layout/Mainlayout';
import Home from './components/Home/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import WhyUs from './pages/WhyUs';
import Services from './pages/Services';
import Project from './pages/Project';
import Gallery from './pages/Gallery';
import MissionVision from './pages/MissionVision';
import OurApproach from './pages/OurApproach';




function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>

      <HashRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Mainlayout />}>
            <Route index element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/missionvision" element={<MissionVision />} />
            <Route path="/whyus" element={<WhyUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/ourapproach" element={<OurApproach />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/project" element={<Project />} />
          </Route>
        </Routes>
      </HashRouter>


    </>
  );
}

export default App;
