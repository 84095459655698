import React from 'react'
import { Link } from 'react-router-dom';


const About = () => {
  return (
    <>

      <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/breadcrumb.jpg)' }}>
        <div className="container">
          <h2 className="breadcrumb-title">ABOUT US</h2>
          <ul className="breadcrumb-menu">
            <li><Link to="/">Home</Link></li>
            <li className="active">About Us</li>
          </ul>
        </div>
      </div>


      <div className="service-single-area py-120">
        <div className="container">
          <div className="service-single-wrapper">
            <div className="row">

              <div className="col-xl-6 col-lg-8">
                <div className="service-details">
                  <div className="service-details-img mb-30">
                    <img src="assets/img/service/single.jpg" alt="thumb" />
                  </div>

                </div>
              </div>

              <div className="col-xl-6 col-lg-4">
                <div className="service-sidebar">
                  <div className="widget category">
                    <h4 className="widget-title">About Us</h4>
                    <div className="category-list">
                      <p className="about-text">
                        Welcome to Facade & Landscape your go-to source for high-quality facade and landscape designing and construction services. Our company is dedicated to providing top-notch services to our clients, backed by years of experience and a commitment to excellence.
                      </p><br></br>
                      <p className="about-text">
                        At Facade & Landscape we believe in the power of beautiful outdoor spaces to enhance the beauty and functionality of your property. Our team of skilled professionals is passionate about creating outdoor spaces that are both visually stunning and functional, from the design phase to the final construction.
                      </p>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="service-single-area py-120 bg">
        <div className="container">
          <div className="service-single-wrapper">
            <div className="row">

              <div className="col-xl-6 col-lg-4">
                <div className="service-sidebar"><br></br>
                  <div className="widget category">
                    <div className="category-list">
                      <p className="about-text">
                        Our team consists of skilled designers, architects, and construction experts who work together to ensure that every project is completed to the highest standards. We use only the best materials and advanced techniques to ensure that your project is built to last and meets your specific needs and preferences.
                      </p><br></br>
                      <p className="about-text">
                        We understand that every project is unique, and that's why we take the time to work closely with our clients to understand their vision and goals. We pride ourselves on our attention to detail, excellent customer service, and commitment to delivering exceptional quality in every project we undertake.
                      </p>
                    </div>
                  </div>

                </div>
              </div>


              <div className="col-xl-6 col-lg-8">
                <div className="service-details">
                  <div className="service-details-img mb-30">
                    <img src="assets/img/service/single1.jpg" alt="thumb" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="service-single-area py-120">
        <div className="container">
          <div className="service-single-wrapper">
            <div className="row">

              <div className="col-xl-6 col-lg-8">
                <div className="service-details">
                  <div className="service-details-img mb-30">
                    <img src="assets/img/service/single2.jpg" alt="thumb" />
                  </div>

                </div>
              </div>


              <div className="col-xl-6 col-lg-4">
                <div className="service-sidebar">
                  <div className="widget category">
                    <div className="category-list">
                      <p className="about-text"><br></br>
                        At Facade & Landscape we believe in sustainability and eco-friendliness in all our projects. We use environmentally friendly materials and techniques whenever possible, to minimize our impact on the environment and create beautiful, sustainable outdoor spaces.
                      </p><br></br>
                      <p className="about-text">
                        Our commitment to excellence has earned us a reputation as one of the top facade and landscape designing and construction companies in the industry. We take pride in our work, and our passion for creating beautiful outdoor spaces is evident in every project we undertake.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




    </>
  )
}

export default About