import React from 'react'
import { Link } from 'react-router-dom';


const Project = () => {
  return (
    <>


      <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/breadcrumb.jpg)' }}>
        <div className="container">
          <h2 className="breadcrumb-title">Project</h2>
          <ul className="breadcrumb-menu">
            <li><Link to="/">Home</Link></li>
            <li className="active">Project</li>
          </ul>
        </div>
      </div>
      <div className="blog-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Project Execution</span>
                <div className="heading-divider" />
                <p>
                  Facade & Landscape we understand that successful project execution is essential to the success of any facade and landscape designing and construction project. Here's what you can expect when you work with us
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/02 (2).jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Planning</p>
                  </h4>
                  <p>We start by carefully planning every aspect of your project, from the design to the materials and construction techniques. We work closely with you to ensure that your project meets your specific needs and budget, and we keep you informed throughout the planning process.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/02.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Budgeting</p>
                  </h4>
                  <p>We provide a detailed and transparent budget for your project, outlining all costs associated with materials, labour, and any other expenses. We believe in open and honest communication with our clients, and we work hard to ensure that you understand every aspect of the budget before we begin construction.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/03 (1).jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Scheduling</p>
                  </h4>
                  <p>We create a detailed schedule for your project, outlining each stage of the project and the timeline for completion. We work closely with you to ensure that the project stays on schedule and on budget, and we keep you informed of any changes or updates throughout the construction process.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/01 (3).png" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Construction</p>
                  </h4>
                  <p>Our skilled contractors will bring your project to life, using high-quality materials and advanced construction techniques to ensure that your project is built to last. We take pride in our attention to detail and our commitment to quality, and we work closely with you throughout the construction process to ensure that your project meets your expectations.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/01 (3).jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Completion</p>
                  </h4>
                  <p>Once your project is complete, we conduct a thorough inspection to ensure that everything meets our high standards of quality and craftsmanship. We then present you with the finished project, and work with you to ensure that you are completely satisfied with the results.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mx-auto">
          <div className="site-heading text-center">
            <span className="site-title-tagline">Project</span>
            <div className="heading-divider" />
          </div>
        </div>
      </div>

      <div className="case-area py-120">
        <div className="container">
          <div className="row popup-gallery">
            <div className="row">

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/7.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/7.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/3.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/3.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#"> Convention Banquet & Function Hall</a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/4.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/4.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>






              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/8.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/8.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/9.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/9.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/10.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/10.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/11.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/11.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#"> Convention Banquet & Function Hall</a></h4>

                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/5.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/5.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>





              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/12.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/12.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/13.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/13.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/14.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/14.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/15.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/15.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#"> Convention Banquet & Function Hall</a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/17.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/17.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/16.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/16.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/6.png" alt />
                    <a className="popup-img case-link" to="assets/img/project/6.png"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>



              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/18.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/18.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/19.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/19.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/20.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/20.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#"> Convention Banquet & Function Hall</a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/21.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/21.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/22.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/22.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/23.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/23.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Convention Banquet & Function Hall </a></h4>

                    </div>
                  </div>
                </div>
              </div>



              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/24.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/24.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/25.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/25.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/26.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/26.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#"> Farm House</a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/27.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/27.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/29.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/29.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/31.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/31.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/30.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/30.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-6 col-lg-4">
                <div className="case-item">
                  <div className="case-img">
                    <img className="img-fluid" src="assets/img/project/28.jpg" alt />
                    <a className="popup-img case-link" to="assets/img/project/28.jpg"> <i className="far fa-plus" /></a>
                  </div>
                  <div className="case-content">
                    <div className="case-content-info">
                      <h4><a className='text-white' to="#">Farm House </a></h4>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div className="blog-details container">
        <blockquote className="blockqoute">
          Facade & Landscape we are committed to delivering exceptional quality and service to our clients, and to ensuring that every project is completed on time, on budget, and to your exact specifications. Contact us today to learn more about our project execution process and how we can help bring your outdoor space to life.
        </blockquote>
      </div>


    </>
  )
}

export default Project