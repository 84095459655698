import React from 'react'
import { Link } from 'react-router-dom';

const Contact = () => {
    return (
        <>

            <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/breadcrumb.jpg)' }}>
                <div className="container">
                    <h2 className="breadcrumb-title">Contact Us</h2>
                    <ul className="breadcrumb-menu">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Contact Us</li>
                    </ul>
                </div>
            </div>


            <div className="contact-area py-120">
                <div className="container">
                    <div className="contact-wrapper">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contact-img">
                                    <img className='rounded' src="assets/img/contact/contact.jpg" alt />
                                </div>
                            </div>
                            <div className="col-md-6 align-self-center">
                                <div className="contact-form">
                                    <div className="contact-form-header">
                                        <h2>CONTACT US</h2>
                                        <p>We’d Love to Hear From You </p>
                                    </div>
                                    <form method="post" action="/interio/assets/php/contact.php" id="contact-form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="name" placeholder="Your Name" required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input type="number" className="form-control" name="subject" placeholder="Your Phone" required />
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" cols={30} rows={5} className="form-control" placeholder="Write Your Message" defaultValue={""} />
                                        </div>
                                        <button type="submit" className="theme-btn"> <i className="far fa-paper-plane" /><Link className='text-white' to="/"> Send Message</Link>
                                        </button>
                                        <div className="col-md-12 mt-3">
                                            <div className="form-messege text-success" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-120">
                        <div className="col-md-6">
                            <div className="contact-content">
                                <div className="contact-info">
                                    <i className="fal fa-map-marker-alt" />
                                    <div>
                                        <h5>Office Address</h5>
                                        <p>Pillar Number 143, # 49 & 50,<br />
                                            4th Floor, United Arcade,
                                            Attapur,<br /> Hyderabad,
                                            Telangana-500048</p>
                                    </div>
                                </div>
                                <div className="contact-info">
                                    <i className="fal fa-phone" />
                                    <div>
                                        <h5>Call Us</h5>
                                        <a href="tel: 8008009832">
                                            (+91) 800 800 9832
                                        </a>
                                    </div>
                                </div>
                                <div className="contact-info">
                                    <i className="fal fa-envelope" />
                                    <div className='mail'>
                                        <h5>Email Us</h5>
                                        <a
                                            href="mailto:  javidarchitects@gmail.com"
                                            class="mail"
                                        >
                                            javidarchitects@gmail.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mapouter"><div className="gmap_canvas"><iframe className="gmap_iframe" width="100%" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.com/maps?width=800&height=400&hl=en&q=Space Design Studio, Pillar N, United Arcade, Attapur, Telangana 500048&t=&z=14&ie=UTF8&iwloc=B&output=embed" /><a href="https://pdflist.com/" alt="pdflist.com">Pdflist.com</a></div><style dangerouslySetInnerHTML={{ __html: ".mapouter{position:relative;text-align:right;width:100%;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:400px;}.gmap_iframe {height:400px!important;}" }} /></div>

                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default Contact