
import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";

const SliderRecentlyProject = () => {
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={5}
        modules={[Autoplay]}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        className="ClientSwiper"
        breakpoints={{
          360: {
            slidesPreView: 2,
            spaceBetween: 5,
          },
          480: {
            slidesPreView: 3,
            spaceBetween: 5,
          },
          720: {
            slidesPreView: 4,
            spaceBetween: 5,
          },
          1080: {
            slidesPreView: 5,
            spaceBetween: 5,
          },
        }}
      >

        <SwiperSlide>
          <div className="case-item">
            <div className="case-img">
              <img className="img-fluid" src="assets/img/case/01.jpg" alt />
              <a className="popup-img case-link" to="assets/img/case/01.jpg"> 
              <i className="far fa-plus" />
              </a>
            </div>
            <div className="case-content">
              <div className="case-content-info">
                <h4><a className='text-white' to="#">Exterior Work</a></h4>
                <small>Exterior</small>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="case-item">
            <div className="case-img">
              <img className="img-fluid" src="assets/img/case/02.png" alt />
              <a className="popup-img case-link" to="assets/img/case/02.png"> <i className="far fa-plus" /></a>
            </div>
            <div className="case-content">
              <div className="case-content-info">
                <h4><a className='text-white' to="#">Exterior Work</a></h4>
                <small>Exterior</small>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="case-item">
            <div className="case-img">
              <img className="img-fluid" src="assets/img/case/03.jpg" alt />
              <a className="popup-img case-link" to="assets/img/case/03.jpg"> <i className="far fa-plus" /></a>
            </div>
            <div className="case-content">
              <div className="case-content-info">
                <h4><a className='text-white' to="#">Exterior Work</a></h4>
                <small>Exterior</small>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="case-item">
            <div className="case-img">
              <img className="img-fluid" src="assets/img/case/05.jpg" alt />
              <a className="popup-img case-link" to="assets/img/case/05.jpg"> <i className="far fa-plus" /></a>
            </div>
            <div className="case-content">
              <div className="case-content-info">
                <h4><a className='text-white' to="#">Exterior Work</a></h4>
                <small>Exterior</small>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="case-item">
            <div className="case-img">
              <img className="img-fluid" src="assets/img/case/04.jpg" alt />
              <a className="popup-img case-link" to="assets/img/case/06.jpg"> <i className="far fa-plus" /></a>
            </div>
            <div className="case-content">
              <div className="case-content-info">
                <h4><a className='text-white' to="#">Exterior Work</a></h4>
                <small>Exterior</small>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="case-item">
            <div className="case-img">
              <img className="img-fluid" src="assets/img/case/03.jpg" alt />
              <a className="popup-img case-link" to="assets/img/case/07.jpg"> <i className="far fa-plus" /></a>
            </div>
            <div className="case-content">
              <div className="case-content-info">
                <h4><a className='text-white' to="#">Exterior Work</a></h4>
                <small>Exterior</small>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="case-item">
            <div className="case-img">
              <img className="img-fluid" src="assets/img/case/01.jpg" alt />
              <a className="popup-img case-link" to="assets/img/case/01.jpg"> <i className="far fa-plus" /></a>
            </div>
            <div className="case-content">
              <div className="case-content-info">
                <h4><a className='text-white' to="#">Exterior Work</a></h4>
                <small>Exterior</small>
              </div>
            </div>
          </div>
        </SwiperSlide>
        

      </Swiper>
    </>
  )
}

export default SliderRecentlyProject

