import React from 'react'
import { Link } from 'react-router-dom';


const MissionVision = () => {
  return (
    <>

      <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/breadcrumb.jpg)' }}>
        <div className="container">
          <h2 className="breadcrumb-title">Mission & Vision</h2>
          <ul className="breadcrumb-menu">
            <li><Link to="/">Home</Link></li>
            <li className="active"> Mission & Vision</li>
          </ul>
        </div>
      </div>


      <div className="service-area bg py-120">
        <div className="container">
          <div className="row g-1 mt-5">
            <div className="col-md-6 col-lg-6">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/01.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-staircase" />
                  </div>
                  <h3 class="service-title">
                    <a href="/">Mission</a>
                  </h3>
                  <p className="service-text">
                  Facade & Landscape our mission is to provide our clients with exceptional facade and landscape designing and construction services, creating beautiful and functional outdoor spaces that exceed their expectations. We strive to deliver projects that are built to last, sustainable, and environmentally friendly, while maintaining the highest levels of quality and professionalism.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/02.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-workspace" />
                  </div>
                  <h3 class="service-title">
                    <a href="/">Vision</a>
                  </h3>
                  <p className="service-text">
                    Our vision Facade & Landscape is to be a leader in the facade and landscape designing and construction industry, known for our innovative solutions, exceptional quality, and commitment to sustainability. We aim to create outdoor spaces that not only enhance the beauty and functionality of our clients' properties but also contribute to a greener, more sustainable future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="blog-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Values</span>
                <div className="heading-divider" />
                <p>
                Facade & Landscape we are guided by our core values, which include
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/01.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Excellence</p>
                  </h4>
                  <p>We are committed to delivering exceptional quality in every project we undertake, from the design phase to the final construction.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/02.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Sustainability</p>
                  </h4>
                  <p>We believe in creating outdoor spaces that are environmentally friendly, using sustainable materials and techniques whenever possible.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/03.png" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Collaboration</p>
                  </h4>
                  <p>We work closely with our clients, architects, and contractors to ensure that every project is completed to the highest standards and meets our clients' specific needs and preferences.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/04.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Innovation</p>
                  </h4>
                  <p>We are constantly seeking new and innovative solutions to improve our services and create outdoor spaces that are both beautiful and functional.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="blog-item">
                <div className="blog-item-img">
                  <img src="assets/img/blog/05.jpg" alt="Thumb" />
                </div>
                <div className="blog-item-info">
                  <h4 class="blog-title text-center">
                    <p>Professionalism</p>
                  </h4>
                  <p>We conduct ourselves with the highest levels of professionalism, treating our clients, employees, and partners with respect and integrity.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details container">
        <blockquote className="blockqoute">
        Facade & Landscape we are passionate about creating outdoor spaces that enhance the beauty and functionality of your property while contributing to a more sustainable future. Contact us today to learn more about our services and how we can help bring your vision to life.
        </blockquote>
      </div>






    </>
  )
}

export default MissionVision