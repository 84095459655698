import React from 'react'
import HomeSlider from './HomeSlider'
import HomeAbout from './HomeAbout'
import RecentlyProject from './RecentlyProject'
import ClientsSwiper from './ClientsSwiper'
import HomeCard from './HomeCard'

const Home = () => {
  return (
    <>
    
    <HomeSlider />
    <HomeAbout />
    <HomeCard />
    <RecentlyProject />
    {/* <ClientsSwiper /> */}

    </>
  )
}

export default Home