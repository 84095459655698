import React from 'react'
import { Link } from 'react-router-dom';


const HomeCard = () => {
  return (
    <>

      <div className="service-area bg py-120 mt-30">
        <div className="container">

          <div className="row g-1">

            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/02.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-workspace" />
                  </div>
                  <p className="service-text">
                  Facade & Landscape we believe that every project is unique, and we work closely with our clients to understand their specific needs and preferences. We take pride in our attention to detail, excellent customer service, and commitment to excellence in every project we undertake.
                  </p>
                  <div className="service-arrow">
                    <Link to="/about" className="theme-btn">Read More<i className="far fa-arrow-right" /></Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/01.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-staircase" />
                  </div>

                  <p className="service-text">
                    Whether you are looking to update your property's facade, revamp your outdoor space, or create a beautiful garden, we have the expertise and experience to make it happen. Our team uses high-quality materials and advanced techniques to ensure that your project is durable, sustainable, and built to last.
                  </p>
                  <div className="service-arrow">
                    <Link to="/about" className="theme-btn">Read More<i className="far fa-arrow-right" /></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-item">
                <div className="service-img">
                  <img src="assets/img/service/04.jpg" alt />
                </div>
                <div className="service-content">
                  <div className="service-icon">
                    <i className="flaticon-interior-design" />
                  </div>

                  <p className="service-text">
                    Thank you for considering Facade & Landscape for your facade and landscape designing and construction needs. Please explore our website to learn more about our services and see examples of our past projects. Contact us today to schedule a consultation and get started on your next project.
                  </p>
                  <div className="service-arrow">
                    <Link to="/about" className="theme-btn">Read More<i className="far fa-arrow-right" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default HomeCard